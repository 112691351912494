
import React from "react";
import ReactDOM from "react-dom";
// import './index.css';
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { RoomProvider } from "./context";
import { BrowserRouter } from "react-router-dom";

const iframe = '<iframe src="https://booking.autoeurope.com/?aff=CJUNCTION&tgt=1&wlif" width="100%" height="600px "style="border:0px;" name="aeSearch" scrolling="no"><p>Your browser can’t load the needed module. Please <a href="http://www.jdoqocy.com/click-9214397-13820707?url=https%3A%2F%2Fwww.autoeurope.com%2F%3Faff%3DCJUNCTION" target="_blank">click here</a> for the best car rental rates.</p></iframe><script src="https://ajax.googleapis.com/ajax/libs/jquery/1.11.0/jquery.min.js"> </script><img src="http://www.ftjcfx.com/image-9214397-13820707" width="1" height="1" border="0"/>';

function Iframe(props) {
  return (<div dangerouslySetInnerHTML={ {__html:  props.iframe?props.iframe:""}} />);
}

const Footer = () => (
  <footer class="footer">
    <p>©2019 - LuxeTravelandLiving. All rights reserved.</p>
  </footer>
);

ReactDOM.render(
  [<RoomProvider>
    <BrowserRouter>
      <App />
      <Iframe iframe={iframe} />
    </BrowserRouter>
  </RoomProvider>,  <Footer key="2" />],
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
