import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon, MDBInput } from 'mdbreact';
import axios from 'axios';
import "@fortawesome/fontawesome-free/css/all.min.css";
import 'mdbreact/dist/css/mdb.css';
import Hero from "../components/Hero";
import Banner from "../components/Banner";
import '../App.css';

class FormPage extends Component {
    state = {
      name: '',
      email: '',
      subject: '',
      message: ''
    }

    handleSubmit(e){

        e.preventDefault();
        
        const name = document.getElementById('name').value;
        const email = document.getElementById('email').value;
        const message = document.getElementById('message').value;

        axios({
            method: "POST", 
            url:"https://us-central1-luxetravel-571f5.cloudfunctions.net/sendMail?dest=jjjattard@gmail.com", 
            data: {
                name: name,   
                email: email,  
                messsage: message
            }
        }).then((response)=>{
            if (response.data.msg === 'success'){
                alert("Message Sent."); 
                this.resetForm()
            }else if(response.data.msg === 'fail'){
                alert("Message failed to send.")
            }
        })
    }

    resetForm(){
        document.getElementById('contact-form').reset();
    }

    render(){
      return (
        <MDBContainer fluid>
            <Hero hero="roomsHero"><Banner title="Write to us"> </Banner></Hero> 
            <MDBRow>
                <MDBCol md='6'>
                    <form onSubmit={this.handleSubmit.bind(this)} method="POST" encType="text/plain" className='hoverable' style={{ padding: 38 }}>
                        <div className='grey-text'>
                            <MDBInput
                                id='name'
                                label='Your Name'
                                name='name'
                                icon='user'
                                group
                                type='text'
                            />
                            <MDBInput
                                id='email'
                                label='E-mail'
                                icon='envelope'
                                name='email'
                                group
                                type='email'
                            />
                            <MDBInput
                                id='subject'
                                label='Subject'
                                name='subject'
                                icon='tag'
                                group
                                type='text'
                            />
                            <MDBInput
                                id='message'
                                type='textarea'
                                rows='2'
                                name='message'
                                label='Your message'
                                icon='pencil-alt'
                            />
                        </div>
                        <div className='text-center'>
                            <MDBBtn outline color='secondary' type="submit">
                                Send Now !!! <MDBIcon far icon='paper-plane' className='ml-1' />
                            </MDBBtn>
                        </div>
                    </form>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
    }

};

export default FormPage;